'use client'

import * as React from 'react'
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react'
import { ChevronLeft, ChevronRight } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'

type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin
  setApi?: (api: CarouselApi) => void
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
} & CarouselProps

const CarouselContext = React.createContext<CarouselContextProps | null>(null)

function useCarousel() {
  const context = React.useContext(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(({ opts, setApi, plugins, className, children, ...props }, ref) => {
  const [carouselRef, api] = useEmblaCarousel(opts, plugins)
  const [canScrollPrev, setCanScrollPrev] = React.useState(false)
  const [canScrollNext, setCanScrollNext] = React.useState(false)

  const onSelect = React.useCallback((api: CarouselApi) => {
    if (!api) {
      return
    }

    setCanScrollPrev(api.canScrollPrev())
    setCanScrollNext(api.canScrollNext())
  }, [])

  const scrollPrev = React.useCallback(() => {
    api?.scrollPrev()
  }, [api])

  const scrollNext = React.useCallback(() => {
    api?.scrollNext()
  }, [api])

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault()
        scrollPrev()
      } else if (event.key === 'ArrowRight') {
        event.preventDefault()
        scrollNext()
      }
    },
    [scrollPrev, scrollNext]
  )

  React.useEffect(() => {
    if (!api || !setApi) {
      return
    }

    setApi(api)
  }, [api, setApi])

  React.useEffect(() => {
    if (!api) {
      return
    }

    onSelect(api)
    api.on('reInit', onSelect)
    api.on('select', onSelect)

    return () => {
      api?.off('select', onSelect)
    }
  }, [api, onSelect])

  return (
    <CarouselContext.Provider
      value={{
        carouselRef,
        api: api,
        opts,
        scrollPrev,
        scrollNext,
        canScrollPrev,
        canScrollNext,
      }}
    >
      <div
        ref={ref}
        onKeyDownCapture={handleKeyDown}
        className={cn('relative flex flex-col gap-3 sm:gap-4', className)}
        role="region"
        aria-roledescription="carousel"
        {...props}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  )
})
Carousel.displayName = 'Carousel'

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef } = useCarousel()

  return (
    <div ref={carouselRef}>
      <div
        ref={ref}
        className={cn('flex flex-row gap-6', className)}
        {...props}
      />
    </div>
  )
})
CarouselContent.displayName = 'CarouselContent'

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        'shrink-0 grow-0 basis-[280px] sm:basis-[320px] lg:basis-[400px]',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
})
CarouselItem.displayName = 'CarouselItem'

const CarouselPagination = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { api, scrollPrev, scrollNext, canScrollPrev, canScrollNext } =
    useCarousel()
  const [activeIndex, setActiveIndex] = React.useState(0)

  React.useEffect(() => {
    if (!api) return
    api.on('select', () => setActiveIndex(api.selectedScrollSnap()))
    api.on('reInit', () => setActiveIndex(api.selectedScrollSnap()))
  }, [api])

  const scrollTo = React.useCallback(
    (index: number) => api && api.scrollTo(index),
    [api]
  )

  if (!api) return null
  const totalSlides = api.scrollSnapList().length
  const showPaginationControls = canScrollNext || canScrollPrev
  return (
    <div
      ref={ref}
      className={cn(
        'flex flex-col items-start justify-start gap-4 pt-2 md:flex-row md:items-center md:justify-between',
        className
      )}
      {...props}
    >
      {showPaginationControls && (
        <>
          <div
            aria-label={`List with ${totalSlides} items`}
            className="flex max-w-full flex-wrap justify-center gap-3"
          >
            {totalSlides <= 8 ? (
              [...Array(totalSlides)].map((_, index) => (
                <Button
                  key={index}
                  variant="carousel-indicator"
                  size="circle-sm"
                  aria-label={
                    index === activeIndex
                      ? `Slide ${index + 1} current`
                      : `Slide ${index + 1}`
                  }
                  onClick={() => scrollTo(index)}
                  className={cn(
                    index === activeIndex
                      ? 'bg-primary text-primary-foreground'
                      : 'bg-background text-foreground ring-1 ring-secondary-form-border hover:ring-foreground focus-visible:bg-background focus-visible:text-foreground focus-visible:shadow-ring focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accessibility-ring'
                  )}
                >
                  <span className="body-xs-bold">{index + 1}</span>

                  <span className="sr-only">{`Go to slide ${canScrollNext}`}</span>
                </Button>
              ))
            ) : (
              <p className="body-sm-regular">{`${activeIndex + 1} of ${totalSlides}`}</p>
            )}
          </div>
          <div className="hidden space-x-4 md:flex">
            <Button
              onClick={scrollPrev}
              disabled={!canScrollPrev}
              variant="carousel-advanced"
              size="circle-lg"
            >
              <ChevronLeft className="size-6 stroke-[5]" />
              <span className="sr-only">Previous slide</span>
            </Button>
            <Button
              onClick={scrollNext}
              disabled={!canScrollNext}
              variant="carousel-advanced"
              size="circle-lg"
            >
              <ChevronRight className="size-6 stroke-[5]" />
              <span className="sr-only">Next slide</span>
            </Button>
          </div>
        </>
      )}
    </div>
  )
})
CarouselPagination.displayName = 'CarouselPagination'

export {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPagination,
  type CarouselApi,
}
