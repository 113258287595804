'use client'

import * as React from 'react'
import Link from 'next/link'
import { cva, type VariantProps } from 'class-variance-authority'
import { ArrowRight } from 'lucide-react'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'

import { ContentfulImage } from './contentful-image'

const videoCardVariants = cva(
  'relative flex size-full overflow-hidden rounded-xl bg-card-primary shadow-lg',
  {
    variants: {
      variant: {
        default: 'flex-col items-start gap-2 pb-6 lg:pb-8',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

interface VideoCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof videoCardVariants> {}

const VideoCard = React.forwardRef<HTMLDivElement, VideoCardProps>(
  ({ className, variant, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(videoCardVariants({ variant, className }))}
      {...props}
    />
  )
)
VideoCard.displayName = 'VideoCard'

interface VideoCardImageProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string
  alt: string
}

const VideoCardImage = React.forwardRef<HTMLDivElement, VideoCardImageProps>(
  ({ className, src, alt, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'group relative aspect-video w-full shrink-0 grow-0 cursor-pointer',
        className
      )}
      {...props}
    >
      <ContentfulImage
        src={src}
        alt={alt}
        fill
        className="object-cover"
        sizes="(max-width: 375px) 100vw, (max-width: 768px) 50vw, 33vw"
      />
    </div>
  )
)
VideoCardImage.displayName = 'VideoCardImage'

const VideoCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col gap-3 px-4', className)}
    {...props}
  />
))
VideoCardContent.displayName = 'VideoCardContent'

const VideoCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn('heading-xs-bold text-card-foreground', className)}
    {...props}
  >
    {children}
  </h3>
))
VideoCardTitle.displayName = 'VideoCardTitle'

const VideoCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-card-foreground/80 body-md-regular', className)}
    {...props}
  />
))
VideoCardDescription.displayName = 'VideoCardDescription'

const VideoCardLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<typeof Link>
>(({ className, children, ...props }, ref) => (
  <Link
    ref={ref}
    className={cn(
      buttonVariants({ variant: 'secondary-link' }),
      'mt-auto items-center justify-start',
      className
    )}
    {...props}
  >
    {children}
    <ArrowRight className="size-3.5" />
  </Link>
))
VideoCardLink.displayName = 'VideoCardLink'

export {
  VideoCard,
  VideoCardContent,
  VideoCardDescription,
  VideoCardImage,
  VideoCardLink,
  VideoCardTitle,
}
