'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'
import { VideoSchema } from '@/contentful/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, type Node } from '@contentful/rich-text-types'
import { ArrowRightIcon, X } from 'lucide-react'
import ReactPlayer, { Config } from 'react-player'
import { WistiaConfig } from 'react-player/wistia'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { ContentfulImage } from '@/components/contentful-image'
import { pushToDataLayer } from '@/components/datalayer'
import {
  VideoCard,
  VideoCardContent,
  VideoCardDescription,
  VideoCardImage,
  VideoCardTitle,
} from '@/components/video-card'

import { gridVariants } from './grid'

interface VideoGridWrapperProps {
  blocks: VideoSchema[]
  className?: string
}

interface VideoEventData {
  playedPercentage?: number
  [key: string]: string | number | undefined
}

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
      const asset = node.data.target.fields
      return (
        <div className="not-prose relative -mb-4 flex h-32 max-w-full md:h-52 lg:mb-2">
          <ContentfulImage src={asset.file.url} alt={asset.description} fill />
        </div>
      )
    },
  },
}

export function VideoCardPaginatedGrid({
  blocks,
  className,
}: VideoGridWrapperProps) {
  const [visibleCount, setVisibleCount] = useState(6)
  const [activeVideo, setActiveVideo] = useState<VideoSchema | null>(null)
  const [activeTranscript, setActiveTranscript] = useState<VideoSchema | null>(
    null
  )
  const [playedPercentage, setPlayedPercentage] = useState(0)
  const hasMoreItems = blocks.length > visibleCount
  const config: WistiaConfig = {}
  const path = usePathname()

  const handleVideoEvent = (
    eventType: string,
    extraData: VideoEventData = {}
  ) => {
    pushToDataLayer({
      event: eventType,
      path: path,
      videoTitle: activeVideo?.fields.title,
      source: activeVideo?.fields.partner,
      ...extraData,
    })
  }

  const handleProgress = (progress: { played: number }) => {
    setPlayedPercentage(Math.round(progress.played * 100))
  }

  return (
    <div className="space-y-7 lg:space-y-9">
      <div className={cn(gridVariants({ layout: 'benefit' }), className)}>
        {blocks.slice(0, visibleCount).map((block) => (
          <VideoCard key={block.fields.internalName}>
            {block.fields.image && (
              <VideoCardImage
                src={`https://${block.fields.image.fields?.file?.url}`}
                alt={
                  block.fields.image.fields?.description ||
                  block.fields.title ||
                  ''
                }
                onClick={() =>
                  block.fields.wistiaVideoId && setActiveVideo(block)
                }
              />
            )}
            <VideoCardContent>
              <div className="mt-auto flex flex-col gap-6">
                {block.fields.videoTranscript && (
                  <button
                    className="body-md-regular ml-auto w-fit cursor-pointer text-card-foreground underline"
                    onClick={() => setActiveTranscript(block)}
                  >
                    View transcript
                  </button>
                )}
                <div className="flex flex-col gap-3">
                  <VideoCardTitle>{block.fields.title}</VideoCardTitle>
                  {block.fields.content && (
                    <VideoCardDescription>
                      {documentToReactComponents(
                        block.fields.content,
                        renderOptions
                      )}
                    </VideoCardDescription>
                  )}
                </div>
                {block.fields.wistiaVideoId && (
                  <Button
                    variant="secondary-link"
                    className="w-fit"
                    onClick={() => setActiveVideo(block)}
                  >
                    {block.fields.cta || 'Watch video'}
                    <ArrowRightIcon
                      aria-hidden="true"
                      className="size-3.5 stroke-[3]"
                    />
                  </Button>
                )}
              </div>
            </VideoCardContent>
          </VideoCard>
        ))}
      </div>

      {activeVideo && (
        <Dialog
          open
          onOpenChange={(open) => {
            if (!open) {
              handleVideoEvent('videoModalClosed', { playedPercentage })
            }
            setActiveVideo(null)
          }}
        >
          <DialogContent
            className="flex max-h-[90vh] max-w-[1200px] items-center justify-center border-none bg-transparent p-0 shadow-none focus-visible:outline-none"
            onInteractOutside={() => setActiveVideo(null)}
            onEscapeKeyDown={() => setActiveVideo(null)}
            aria-hidden
          >
            <DialogClose asChild>
              <Button
                variant="carousel-indicator"
                className="absolute right-5 top-4 z-10"
              >
                <X />
                <span className="sr-only">Close</span>
              </Button>
            </DialogClose>
            <div className="relative aspect-video w-full max-w-[1200px]">
              <ReactPlayer
                width="100%"
                height="100%"
                url={`https://support.wistia.com/medias/${activeVideo.fields.wistiaVideoId}`}
                controls={true}
                config={config as Config}
                onStart={() => handleVideoEvent('videoStarted')}
                onPause={() =>
                  handleVideoEvent('videoPaused', { playedPercentage })
                }
                onEnded={() =>
                  handleVideoEvent('videoEnded', { playedPercentage })
                }
                onProgress={handleProgress}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}

      {activeTranscript && (
        <Dialog open onOpenChange={() => setActiveTranscript(null)}>
          <DialogContent className="flex max-h-[80vh] max-w-[350px] flex-col border-olive-400 shadow-none sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle className="!heading-lg-regular text-left">
                {activeTranscript.fields.title}
                <hr className="border-olive-400" />
              </DialogTitle>
            </DialogHeader>
            <div className="prose grow overflow-y-auto py-4 pr-4 prose-hr:border-olive-400">
              {documentToReactComponents(
                activeTranscript.fields.videoTranscript!
              )}
            </div>
            <DialogClose asChild>
              <Button
                className="absolute right-5 top-4 sm:right-5"
                variant={'primary-accordion-link'}
              >
                <X />
                <span className="sr-only">Close</span>
              </Button>
            </DialogClose>
          </DialogContent>
        </Dialog>
      )}

      {hasMoreItems && (
        <div className="flex justify-center">
          <Button
            variant="primary-outline"
            className="w-full"
            onClick={() => setVisibleCount((prev) => prev + 6)}
            data-testid="load-more-button"
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}
