import React from 'react'
import Image from 'next/image'

import { cn } from '@/lib/utils'

type BenefitsCardProps = React.HTMLAttributes<HTMLDivElement>

const BenefitsCard = React.forwardRef<HTMLDivElement, BenefitsCardProps>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex flex-col rounded-xl bg-card-primary shadow-lg',
        'py-6 sm:py-6 md:py-6 lg:py-8',
        'size-full',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
)
BenefitsCard.displayName = 'BenefitsCard'

const BenefitsCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex h-full flex-col gap-7 px-4 lg:gap-8', className)}
    {...props}
  >
    {children}
  </div>
))
BenefitsCardContent.displayName = 'BenefitsCardContent'

const BenefitsCardTop = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex grow flex-col gap-6', className)}
    {...props}
  >
    {children}
  </div>
))
BenefitsCardTop.displayName = 'BenefitsCardTop'

const BenefitsCardTextContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col gap-3', className)} {...props}>
    {children}
  </div>
))
BenefitsCardTextContent.displayName = 'BenefitsCardTextContent'

const BenefitsCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn('heading-xs-bold text-card-foreground', className)}
    {...props}
  >
    {children}
  </h3>
))
BenefitsCardTitle.displayName = 'BenefitsCardTitle'

const BenefitsCardDescription = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('body-lg-regular text-card-foreground', className)}
    {...props}
  >
    {children}
  </div>
))
BenefitsCardDescription.displayName = 'BenefitsCardDescription'

interface BenefitsCardImageProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string
  alt: string
}

const BenefitsCardImage = React.forwardRef<
  HTMLDivElement,
  BenefitsCardImageProps
>(({ className, src, alt, ...props }, ref) => (
  <div ref={ref} className={cn('relative aspect-[3/1]', className)} {...props}>
    <Image className="object-cover" src={src} alt={alt} fill quality={25} />
  </div>
))
BenefitsCardImage.displayName = 'BenefitsCardImage'

const BenefitsCardAction = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex justify-start', className)} {...props}>
    {children}
  </div>
))
BenefitsCardAction.displayName = 'BenefitsCardAction'

export {
  BenefitsCard,
  BenefitsCardAction,
  BenefitsCardContent,
  BenefitsCardDescription,
  BenefitsCardImage,
  BenefitsCardTextContent,
  BenefitsCardTitle,
  BenefitsCardTop,
}
