import * as React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

import { ContentfulImage } from './contentful-image'
import { linkVariants } from './ui/button'

const CategoryCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex w-full flex-col overflow-hidden rounded-xl bg-card-quaternary focus-within:outline-none focus-within:ring-2 focus-within:ring-accessibility-ring',
      className
    )}
    {...props}
  />
))
CategoryCard.displayName = 'CategoryCard'

interface CategoryCardImageProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string
  alt: string
}

const CategoryCardImage = React.forwardRef<
  HTMLDivElement,
  CategoryCardImageProps
>(({ className, src, alt, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('relative w-full', 'aspect-[3/2] lg:aspect-[3/4]', className)}
    {...props}
  >
    <ContentfulImage
      src={src}
      alt={alt}
      fill
      className="object-cover"
      sizes="(max-width: 375px) 100vw, (max-width: 768px) 50vw, 33vw"
    />
  </div>
))
CategoryCardImage.displayName = 'CategoryCardImage'

const categoryCardLinkVariants = cva('', {
  variants: {
    variant: {
      'dark-olive': 'bg-card-quaternary',
      'light-blue': 'bg-card-octonary',
      tomato: 'bg-card-senary',
      mauve: 'bg-card-denary',
    },
    defaultVariants: {
      variant: 'dark-olive',
    },
  },
})
interface CategoryCardLinkProps
  extends React.ComponentPropsWithoutRef<typeof Link>,
    VariantProps<typeof categoryCardLinkVariants> {
  children: React.ReactNode
}

const CategoryCardImageLink = React.forwardRef<
  HTMLAnchorElement,
  CategoryCardLinkProps
>(({ className, children, variant, ...props }, ref) => (
  <Link
    ref={ref}
    className={cn(
      'flex items-center justify-between gap-4',
      categoryCardLinkVariants({ variant }),
      'text-card-foreground',
      'px-7 py-6',
      'body-lg-bold',
      className
    )}
    {...props}
  >
    <span>{children}</span>
    <Image
      src="/assets/svg/arrow-right.svg"
      alt="Arrow right"
      aria-hidden="true"
      width={14}
      height={14}
    />
  </Link>
))
CategoryCardImageLink.displayName = 'CategoryCardImageLink'

const CategoryCardPillLink = React.forwardRef<
  HTMLAnchorElement,
  CategoryCardLinkProps
>(({ className, children, ...props }, ref) => (
  <Link
    ref={ref}
    className={cn(
      'flex items-center',
      'rounded-full bg-card-tertiary text-card-foreground',
      'px-3 py-2 md:p-4 lg:px-7 lg:py-6',
      'body-sm-bold md:body-md-bold lg:body-lg-bold',
      'gap-4',
      linkVariants({ variant: 'default' }),

      className
    )}
    {...props}
  >
    <span>{children}</span>
    <Image
      src="/assets/svg/arrow-right.svg"
      alt="Arrow right"
      aria-hidden="true"
      width={14}
      height={14}
    />
  </Link>
))
CategoryCardPillLink.displayName = 'CategoryCardPillLink'

export {
  CategoryCard,
  CategoryCardImage,
  CategoryCardImageLink,
  CategoryCardPillLink,
}
