import Link from 'next/link'
import {
  documentToReactComponents,
  type Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, Node } from '@contentful/rich-text-types'
import { cva } from 'class-variance-authority'
import { AssetFields } from 'contentful'
import { ArrowRightIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button, linkVariants } from '@/components/ui/button'
import {
  BenefitsCard,
  BenefitsCardAction,
  BenefitsCardContent,
  BenefitsCardDescription,
  BenefitsCardImage,
  BenefitsCardTextContent,
  BenefitsCardTitle,
  BenefitsCardTop,
} from '@/components/benefits-card'
import {
  CategoryCard,
  CategoryCardImage,
  CategoryCardImageLink,
} from '@/components/category-card'
import { ContentfulImage } from '@/components/contentful-image'
import {
  FeaturedCard,
  FeaturedCardAction,
  FeaturedCardContent,
  FeaturedCardDescription,
  FeaturedCardImage,
  FeaturedCardTextContent,
  FeaturedCardTitle,
} from '@/components/featured-card'
import {
  StandardCard,
  StandardCardAction,
  StandardCardContent,
  StandardCardDescription,
  StandardCardEyebrow,
  StandardCardTitle,
} from '@/components/standard-card'

import { getVendorSlug } from '../../actions'
import { VideoCardPaginatedGrid } from './video-card-paginated-grid'

type CardVariant =
  | 'Headline & Paragraph/Bulletpoints'
  | 'Stat'
  | 'Category'
  | 'Benefit'
  | 'Featured'
  | 'Video'

type GridProps = {
  blocks: CardBlocks[]
  variant: CardVariant
  className?: string
}

type CardBlocks = {
  fields: CardProps
}

type CardProps = {
  backgroundColor: string
  eyebrow?: string
  content: Document
  headline: string
  image?: {
    fields: AssetFields
  }
  cta?: {
    fields: {
      name: string
      href: string
    }
  }
  href?: string
  vendor?: string | undefined
}

const gridCols = [
  'lg:grid-cols-1',
  'lg:grid-cols-2',
  'lg:grid-cols-3',
  'lg:grid-cols-4',
  'lg:grid-cols-5',
  'lg:grid-cols-6',
] as const

export const gridVariants = cva(
  'grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-x-4 md:gap-y-7',
  {
    variants: {
      layout: {
        standard: 'lg:gap-8',
        benefit: 'lg:grid-cols-3 lg:gap-x-8 lg:gap-y-7',
        featured: 'lg:grid-cols-1',
        category: 'lg:grid-cols-4',
        stat: 'lg:gap-8',
      },
    },
    defaultVariants: {
      layout: 'standard',
    },
  }
)

const StandardCardGrid = ({ blocks, className }: GridProps) => {
  const columnClass = gridCols[Math.min(blocks.length, 6) - 1]

  return (
    <div
      className={cn(
        gridVariants({ layout: 'standard' }),
        columnClass,
        className
      )}
    >
      {blocks.map(({ fields }) => (
        <StandardCard
          key={fields.headline}
          variant={
            fields.backgroundColor === 'Light Olive' ? 'light-olive' : 'white'
          }
        >
          <StandardCardContent>
            {fields.eyebrow && (
              <StandardCardEyebrow>{fields.eyebrow}</StandardCardEyebrow>
            )}
            <StandardCardTitle>{fields.headline}</StandardCardTitle>
            <StandardCardDescription>
              {documentToReactComponents(fields.content, renderOptions)}
            </StandardCardDescription>
            <StandardCardAction>
              {fields.cta && (
                <Link href={fields.cta.fields.name} passHref legacyBehavior>
                  <Button variant="secondary-link">
                    {fields.cta.fields.name}{' '}
                    <ArrowRightIcon aria-hidden="true" className="size-3.5" />
                  </Button>
                </Link>
              )}
            </StandardCardAction>
          </StandardCardContent>
        </StandardCard>
      ))}
    </div>
  )
}

const StatCardGrid = ({ blocks, className }: GridProps) => {
  const columnClass = gridCols[Math.min(blocks.length, 6) - 1]

  return (
    <div
      className={cn(gridVariants({ layout: 'stat' }), columnClass, className)}
    >
      {blocks.map(({ fields }) => (
        <StandardCard
          key={fields.headline}
          variant={
            fields.backgroundColor === 'Light Olive' ? 'light-olive' : 'white'
          }
        >
          <StandardCardContent className="items-center">
            {fields.eyebrow && (
              <StandardCardEyebrow>{fields.eyebrow}</StandardCardEyebrow>
            )}
            <StandardCardTitle className="font-lyon text-7xl font-normal md:text-11xl lg:text-12xl">
              {fields.headline}
            </StandardCardTitle>
            <StandardCardDescription className="text-center">
              {documentToReactComponents(fields.content, renderOptions)}
            </StandardCardDescription>
            <StandardCardAction>
              {fields.cta && (
                <Link href={fields.cta.fields.name} passHref legacyBehavior>
                  <Button variant="secondary-link">
                    {fields.cta.fields.name}{' '}
                    <ArrowRightIcon aria-hidden="true" className="size-3.5" />
                  </Button>
                </Link>
              )}
            </StandardCardAction>
          </StandardCardContent>
        </StandardCard>
      ))}
    </div>
  )
}

const CategoryCardGrid = ({ blocks, className }: GridProps) => {
  return (
    <div className={cn(gridVariants({ layout: 'category' }), className)}>
      {blocks.map(({ fields }) => (
        <div key={fields.headline} className="max-w-72 md:max-w-80">
          <CategoryCard>
            {fields.image && (
              <CategoryCardImage
                src={`https://${fields.image?.fields?.file?.url}`}
                alt={
                  (fields.image?.fields?.description as string) ||
                  (fields.image?.fields.title as string)
                }
              />
            )}
            <CategoryCardImageLink href="#">
              {fields.headline}
            </CategoryCardImageLink>
          </CategoryCard>
        </div>
      ))}
    </div>
  )
}

const BenefitCardGrid = async ({ blocks, className }: GridProps) => {
  const cardsData = await Promise.all(
    blocks.map(async ({ fields }) => {
      const { vendor, cta } = fields
      const vendorSlug = await getVendorSlug(vendor)
      const href = vendorSlug ?? cta?.fields.href ?? ''
      return { fields, href }
    })
  )

  return (
    <div className={cn(gridVariants({ layout: 'benefit' }), className)}>
      {cardsData.map(({ fields, href }) => (
        <BenefitsCard key={fields.headline}>
          <BenefitsCardContent>
            <BenefitsCardTop>
              <BenefitsCardTextContent>
                <BenefitsCardTitle>{fields.headline}</BenefitsCardTitle>
                <BenefitsCardDescription>
                  {documentToReactComponents(fields.content, renderOptions)}
                </BenefitsCardDescription>
              </BenefitsCardTextContent>
            </BenefitsCardTop>
            {fields.image && (
              <Link
                href={href}
                className={cn(
                  {
                    'pointer-events-none': !href,
                  },
                  linkVariants({ variant: 'default' })
                )}
              >
                <BenefitsCardImage
                  src={`https://${fields.image.fields.file!.url}`}
                  alt={
                    (fields.image.fields.description ||
                      fields.image.fields.title)!
                  }
                />
              </Link>
            )}
            <BenefitsCardAction>
              {fields.cta && (
                <Button
                  asChild
                  variant="secondary-link"
                  className={cn({
                    'pointer-events-none': !href,
                  })}
                >
                  <Link href={href}>
                    Learn more
                    <ArrowRightIcon aria-hidden="true" className="size-3.5" />
                  </Link>
                </Button>
              )}
            </BenefitsCardAction>
          </BenefitsCardContent>
        </BenefitsCard>
      ))}
    </div>
  )
}

const FeaturedCardGrid = ({ blocks, className }: GridProps) => {
  return (
    <div className={cn(gridVariants({ layout: 'featured' }), className)}>
      {blocks.map(({ fields }) => (
        <div key={fields.headline} className="col-span-full">
          <FeaturedCard>
            <FeaturedCardImage src={fields.image?.fields?.file?.url} alt="" />
            <FeaturedCardContent>
              <FeaturedCardTextContent>
                <FeaturedCardTitle as="h3">{fields.headline}</FeaturedCardTitle>
                <FeaturedCardDescription>
                  {documentToReactComponents(
                    fields.content,
                    featuredCardRenderOptions
                  )}
                </FeaturedCardDescription>
              </FeaturedCardTextContent>
              <FeaturedCardAction>
                {fields.cta && (
                  <Link
                    aria-hidden="true"
                    href={fields.cta?.fields?.href || ''}
                    passHref
                    tabIndex={-1}
                  >
                    <Button
                      variant="secondary-link"
                      role="link"
                      aria-label={fields.cta.fields.name}
                    >
                      {fields.cta.fields.name}{' '}
                      <ArrowRightIcon
                        aria-hidden="true"
                        className="size-3.5 stroke-[3]"
                      />
                    </Button>
                  </Link>
                )}
              </FeaturedCardAction>
            </FeaturedCardContent>
          </FeaturedCard>
        </div>
      ))}
    </div>
  )
}

const VideoCardGrid = ({ blocks, className }: GridProps) => {
  return <VideoCardPaginatedGrid blocks={blocks} className={className} />
}

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
      const asset = node.data.target.fields
      return (
        <div className="not-prose relative -mb-4 flex h-32 md:h-52 lg:mb-2 ">
          <ContentfulImage src={asset.file.url} alt={asset.description} fill />
        </div>
      )
    },
  },
}

const featuredCardRenderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="body-lg-regular">{children}</p>
    ),
  },
}

export const Grid = async ({ variant, ...props }: GridProps) => {
  const GridComponent = {
    'Headline & Paragraph/Bulletpoints': StandardCardGrid,
    Stat: StatCardGrid,
    Category: CategoryCardGrid,
    Benefit: BenefitCardGrid,
    Featured: FeaturedCardGrid,
    Video: VideoCardGrid,
  }[variant]

  return <GridComponent variant={variant} {...props} />
}
