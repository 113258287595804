'use client'

import { useEffect } from 'react'

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <div className="flex w-full flex-col items-center justify-center gap-3 px-5 py-10 text-center md:px-8 md:py-12 lg:px-11 lg:py-15">
      <h1 className="heading-hero-regular">Something went wrong.</h1>
      <p className="support-styles-intro-text-regular">
        Oops, looks like something went wrong.
      </p>
    </div>
  )
}
