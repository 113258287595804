'use client'

import { CategorySchema } from '@/contentful/shared'

import { contentConfig } from '@/config/content'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPagination,
} from '@/components/ui/carousel'
import { CategoryCardPillLink } from '@/components/category-card'

type SubcategoryCarouselProps = {
  fields: {
    internalName: string
    title: string
    description: string
  }
  categories: CategorySchema[]
}

interface UniqueSubcategory {
  category: string
  label: string
  value: string
}

function getUniqueSubcategories(
  categories: CategorySchema[]
): UniqueSubcategory[] {
  const uniqueSubcategories = new Map<string, UniqueSubcategory>()
  categories
    .flatMap((category) =>
      (category.fields.subCategories || []).map((subcategory) => ({
        category: category.fields.slug,
        label: subcategory?.fields.title || '',
        value: subcategory?.fields.slug || '',
      }))
    )
    .forEach((subcategory) =>
      uniqueSubcategories.set(subcategory.value, subcategory)
    )

  return Array.from(uniqueSubcategories.values())
}

export function AllFeaturedTags({
  fields,
  categories,
}: SubcategoryCarouselProps) {
  const uniqueSubcategories = getUniqueSubcategories(categories)
  return (
    <div className="relative -mx-[calc((100vw-100%)/2)] flex justify-center overflow-hidden">
      <div className="flex w-full max-w-screen-2xl flex-col gap-7 bg-background p-5 sm:gap-6 sm:px-9 sm:py-7 md:px-8 lg:px-11 lg:pb-11 lg:pt-8">
        <p className="heading-xs-bold text-foreground">{fields?.title}</p>
        <Carousel
          opts={{
            align: 'start',
            dragFree: true,
          }}
        >
          <CarouselContent className="flex flex-row gap-2 pb-3 md:gap-4 md:pb-4 lg:pb-0 ">
            {uniqueSubcategories.map((subcategory) => (
              <CarouselItem
                key={`${subcategory.category}-${subcategory.value}`}
                className="!basis-auto"
              >
                <CategoryCardPillLink
                  href={contentConfig.subcategoryPath(
                    `${subcategory.category}`,
                    subcategory.value
                  )}
                >
                  {subcategory.label}
                </CategoryCardPillLink>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPagination />
        </Carousel>
      </div>
    </div>
  )
}
